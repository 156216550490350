import React from 'react';
// import { CloseButton } from 'react-bootstrap';
import './MatchBio.css';
// import { ArrowDown } from 'react-bootstrap-icons';
import 'animate.css'
import ProtTypes from 'prop-types';
// type animation
import {TypeAnimation} from 'react-type-animation'


export default function MatchBio({ biodata,  handleCloseBio}) {
  const parsedMatchData = biodata && biodata["parsed-match-data"];
//   const [disableGetprompt, setDisableGetPrompt] = useState(false);
  const matchDataObject = parsedMatchData && JSON.parse(parsedMatchData);
  const name = matchDataObject && matchDataObject.name;
  const displayString = 
`Let's get you talking to ${name}!\n
Here's what we've gathered about your match`;

  
    // const handleGetPropt = () => {
    //     setGetPrompt(true);
    //     setDisableGetPrompt(true);

    // };
  return (
        // <Container className='d-flex flex-column justify-space-between' >
        <div className="max-w-all rounded overflow-hidden shadow-lg p-8 position-relative">
                <div className='mb-8 max-w-lg max-auto lg:mx-0 lg:text-left ' >
                

                    <button className="position-absolute start-0 top-0 text-black w-9 h-10"
                    aria-label="Close"
                    onClick={handleCloseBio}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hover:shadow-md hover:bg-white hover:bg-opacity-50" >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                     {/* {name && `Let's get you talking to ${name}`} */}
                     {name && 
                        <TypeAnimation sequence={[displayString, 2000,]} 
                            speed={60}
                            className='text-black font-secondary'
                            wrapper='span'
                            repeat={Infinity}
                        />
                     }
                </div>
                <div className='text-center mt-5'>
                    {/* <p>Scroll for prompts</p> */}
                    {/* <ArrowDown className='fs-3' /> */}
                    {/*!disableGetprompt && <button className='ma-9 btn btn-sm' onClick={handleGetPropt} >Click for Prompts!</button>*/}
                </div>
        {/* </Container> */}
        </div>
  );
}

MatchBio.propTypes = {
    biodata: ProtTypes.object,
    handleCloseBio: ProtTypes.func,
    // setGetPrompt: ProtTypes.func
}
