import React from 'react'
// import { UserAuth } from '../Auth/AuthContext';
// import { Link, useLocation } from 'react-router-dom';
export default function NavBar() {
    // const {isAuthenticated} = UserAuth();
    // let location = useLocation();
    // const handleSignOut = async() => {
    //     try {
    //         await logOut();
    //         setIsAuthenticated(false);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
  return (
  <header className='mt-3'>
    <div className='container mx-auto'>
      <div className='flex justify-between items-center'>
        {/* logo */}
        <span className='flex flex-row'>
        <h1 className='text-2xl text-accent-primary font-bold'>
          slideme.
        </h1>
        <h1 className='text-2xl text-accent-secondary font-bold'>
          in
        </h1>
        </span>
      </div>
    </div>
  </header>
  )
}
