import React, { useState} from 'react';
// import axios from 'axios';
import { Spinner,  Stack } from 'react-bootstrap';
import './ImageUploader.css'
import ImagePreview from '../ImagePreview/ImagePreview';
import PropTypes from 'prop-types';
import { UserAuth } from '../Auth/AuthContext';
import { Alert } from 'react-bootstrap';
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom';
import HeroImage from '../../assets/feature1.png';
import {TypeAnimation} from 'react-type-animation'
// import {fadeIn} from '../../variants'
import {motion} from 'framer-motion'
const UploadStatus = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
};
const ImageUploader = ({  onUploadSuccess }) => {
  const [images, setImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(null);
  const {ApiClient} = UserAuth();
  const navigate = useNavigate();
  const handleImageChange = (e) => {
    const newImages = Array.from(e.target.files).filter((image) => {
      return !images.some((existingImage) => existingImage.name === image.name);
    });
    setImages([...images, ...newImages]);
    document.getElementById('fileInput').value = '';
  };

  const handleUpload = async () => {
    if (!images.length) {
      alert('Please select images to upload.');
      return;
    }

    setUploadStatus(UploadStatus.LOADING);
    const compressedImages = [];
    for (const image of images) {
      const compressedImage = await imageCompression(image, {
        maxSizeMB: 0.2, // Set the maximum size of the compressed image to 1MB
        maxWidthOrHeight: 1920, // Set the maximum width or height of the compressed image to 1920px
        fileType: 'image/jpeg' // Convert the image to JPEG format
      });
      compressedImages.push(compressedImage);
    }
    // console.log(compressedImages);

    const formData = new FormData();

    compressedImages.forEach((image) => {
      formData.append(`images`, image, image.name);
    });


    try {
      // this call is made to the backend
      // const response = await axios.post(`${baseUrl}/api/process-images`, formData, {
      //   headers: { 
      //     'Content-Type': 'multipart/form-data' ,
      //     Authorization: `Bearer ${token}`
      //   },
      // });
      const response = await ApiClient.post('/api/process-images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          }
      });
      console.log(response.data)
      // Handle the response as needed

      setUploadStatus(UploadStatus.LOADED);
      onUploadSuccess(response.data);
      setImages([]);
    } catch (error) {
      // console.log(error);
      if(error.response && error.response.status === 401){
        navigate(0);
      }
      // Handle errors here
      console.error('Error uploading images:', error);
      setUploadStatus(UploadStatus.FAILED);
    } 
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      return newImages;
    });
  };

  const handleClearImages = () => {
    setImages([]);
  };

  return (
    <>
    <div className={uploadStatus === 'loading' ? 'hidden' : ''}>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        multiple
        autoComplete='off'
        onChange={handleImageChange}
        style={{ display: 'none' }}
      />
      <Stack gap={3}>
        <ImagePreview images={images} handleRemoveImage={handleRemoveImage} />
        <Stack direction='horizontal' gap={3 } className="justify-content-end">
          {/* <button className='btn-link text-gradient' onClick={handleSignOut}>Log Out</button> */}
            <button className='btn btn-quaternary mb-8 xl:mb-0' onClick={handleClearImages} disabled={uploadStatus == 'loading'}>
                Clear
            </button>
            <button className="btn btn-secondary mb-8 xl:mb-0" onClick={() => document.getElementById('fileInput').click()} disabled={uploadStatus == 'loading'} >
                Select Images
            </button>
            <button className="btn btn-primary mb-8 xl:mb-0" onClick={handleUpload} disabled={(uploadStatus == 'loading') || images.length === 0}>
                {(uploadStatus == 'loading') ? <Spinner animation="border" size="sm" /> : 'Get Talking!'}
            </button>
        </Stack>
        {(uploadStatus == 'failed') && <Alert variant="danger">Upload failed. Please try again.</Alert>}
      </Stack>
    </div>
    <div className={uploadStatus === 'loading' ? 'flex flex-col justify-center items-center w-full' : 'hidden'}>
        <motion.div 
          // className='flex justify-center items-center'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}>
          <motion.img src={HeroImage} alt=''
            className="w-24 h-24 animate-pulse animate-ping shadow-lg rounded-full" />
        </motion.div>
      <TypeAnimation sequence={['Building the perfect icebreakers just for you...', 
                                'Our advanced algorithms are analyzing your screenshots to create personalized prompts...',
                                'Parsing information from the images to generate thought-provoking conversation starters...',
                                2000,]} 
                            speed={60}
                            className='text-grey font-secondary mt-3 font text-sm'
                            wrapper='span'
                            repeat={Infinity}/>
      </div>
    </>
  );
};

ImageUploader.propTypes = {
  // baseUrl: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func.isRequired
};



export default ImageUploader;
