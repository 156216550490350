import React, { useEffect, 
    // useRef 
} from 'react';
// import GoogleLoginButton from 'react-google-button';
import { UserAuth } from './AuthContext';
import ReCAPTCHA from "react-google-recaptcha"
// import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Signin = () => {
    // const captchaRef = useRef(null);
    const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    const { 
        // googleSignIn, 
        verifyToken,
        captchaSignIn,
        // token,
        // isAuthenticated 
    } = UserAuth();

    const navigate = useNavigate();

    // const handleGoogleSignIn = async() => {
    //     try {
    //         await googleSignIn();
    //         // setIsAuthenticated(true);
            
    //     }   catch (error) {
    //         console.log(error);
    //     }
    // }
    function handleRecaptchaLoad() {
        const recaptcha = document.getElementById('my-recaptcha-element');
        recaptcha.style.display = 'block'; // Access the ReCaptcha element's style property
    }
    const handleCaptcha = async (token) => {
        // console.log("on change token",token);
        await captchaSignIn(token);
        if(verifyToken){
            navigate('/');
        }

    };
    useEffect(() => {
        // console.log("isAuthenticated",isAuthenticated)
        // if(localStorage){
        //     localStorage.removeItem('_grecaptcha');
        // }
        // let token = captchaRef.current.getValue();
        // console.log("token",token)
        // verifyToken();
        if(localStorage && localStorage.getItem('authToken')){
            if(verifyToken) {
                navigate('/')
            }
        }
        // navigate('/')
    },[navigate])

    return (
        <div >
            {/* <div className="g-recaptcha" data-sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}></div> */}
                    <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY}
                    // ref={captchaRef}
                    onLoad={handleRecaptchaLoad}
                    onChange={handleCaptcha} size='normal'/>
        </div>
    );
};
  
export default Signin;