import React from 'react'

export default function Instructions() {
  return (
    // <div className='mb-5'>
    //     <p className='text-2xl'>All you&apos;ve got to do</p>
    //     <p className='text-2xl'>- </p>
    //     <p className='text-2xl'>Upload a photo to get started</p>
    //     <p className='text-2xl'>Upload a photo to get started</p>
    //     <p className='text-2xl'>Upload a photo to get started</p>
    //     <p className='text-2xl'>Upload a photo to get started</p>
    // </div>
    // <meta charset="UTF-8">
<div className='mb-3 mt-3'>
  <ul className="list-none">
    <li className="mb-4">&gt; Upload your match&quot;s profile screenshots.</li>
    <li className="mb-4">&gt; Click &quot;Get Talking!&quot; to get personalized icebreakers.</li>
    <li className="mb-4">&gt; Use our prompts to break the ice and connect like a pro!😉</li>
  </ul>
</div>
  )
}
