import React from 'react';
import { CloseButton } from 'react-bootstrap';
import './ImagePreview.css';
import PropTypes from 'prop-types';

const ImagePreview = ({ images, handleRemoveImage }) => {
  return (
    (images.length === 0)? 
    <div className="image-preview-container">
      <p style={{ color: 'black' }}>No Images added</p>
    </div>:
    <div className="mt-3 d-flex overflow-auto flex-nowrap image-preview-container">
      {images.map((image, index) => (
        <div key={index} className="position-relative me-3 mb-3 image-preview-thumbnail-container">
          <img
            src={URL.createObjectURL(image)}
            alt={`preview-${index}`}
            className="img-thumbnail rounded image-preview-thumbnail"
          />
          <div className="position-absolute top-0 end-0" >
          <CloseButton
            aria-label="Close"
            onClick={() => handleRemoveImage(index)}
          ></CloseButton>
          </div>
        </div>
      ))}
    </div>
  );
};

ImagePreview.propTypes = {  
  images: PropTypes.array.isRequired,
  handleRemoveImage: PropTypes.func.isRequired 
}


export default ImagePreview;
