import React from 'react'
import { Navigate } from 'react-router-dom'
// import { UserAuth } from './AuthContext'
import PropTypes from 'prop-types';
const Protected = ({children}) => {
    if(localStorage){
        var token = localStorage.getItem('authToken');
    }
    if(!token) {
        return <Navigate to='/signin' />
    }
    return children;
  
}

Protected.propTypes = {
    children: PropTypes.node.isRequired
}

export default Protected;