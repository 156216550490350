import React from 'react';
import { ButtonGroup, Card} from "react-bootstrap";
import { useEffect, useState } from "react";
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import PropTypes    from 'prop-types';
import { UserAuth } from '../Auth/AuthContext';
const fetchTextFromAPI = async (ApiClient, navigate,biodata) => {
    
    var text = null;
    const payload = {
        "match-info": biodata["parsed-match-data"],
        "user-info": "gburdell",
        "match-id" : biodata["match-id"]

    }
    console.log("payload: ", payload)

    // Replace this function with the actual API call
    try {
        // this call is made to the backend
        const response = await ApiClient.post(`/api/suggestions`, payload, {
          headers: { 
            'Content-Type': 'application/json'
           },
        });
        text = JSON.parse(response.data["suggestions"]);
        
      } catch (error) {
        // Handle errors here
        if(error.response && error.response.status === 401) {
          navigate(0);
        }
        console.error('Error uploading images:', error);
        
      } 
      return new Promise((resolve) => {
        resolve(text);
    });
};
const PlaceholderCard = () => (
    <Card className="text-start mb-5">
      <Card.Body>
        <Card.Title className='placeholder-glow'>
        <span className='placeholder col-6'></span>  
        </Card.Title>
        <Card.Text className='placeholder-glow'>
          <span className='placeholder col-7'> </span>
          <span className='placeholder col-4'></span>
          <span className='placeholder col-4'></span>
          <span className='placeholder col-6'></span>
          <span className='placeholder col-8'></span>
        </Card.Text>     
      </Card.Body>
    </Card>
  );
  const ActualCard = ({ text }) => {
    // console.log(text);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
  
    const handleNextClick = () => {
      if (currentTextIndex < Object.keys(text).length - 1) {
        setCurrentTextIndex((prevIndex) => prevIndex + 1);
      }
    };
  
    const handlePrevClick = () => {
      if (currentTextIndex > 0) {
        setCurrentTextIndex((prevIndex) => prevIndex - 1);
      }
    };
  
    let entries = Object.entries(text);
    const newEntries = new Array();
    const tagsMap = new Map(entries.map(([key, value]) => {
      const regex = /\[([^\]]+)\]/g;
      const match = value.match(regex);
      
      const tag = match ? match[0] : null;
      console.log(tag);
      const newValue = tag ? value.replace(regex, '').trim() : value.trim();
      newEntries.push([key, newValue]);
      return [key, tag, newValue];
    }));
    entries = newEntries;
    console.log(entries);
    console.log("Tags Map",tagsMap);
    
    return (
      
      <Card className='mx-auto'>
        <Card.Body>
          <Card.Title>{entries[currentTextIndex][0]}</Card.Title>
          <Card.Text>{entries[currentTextIndex][1]}</Card.Text>

          
          <Card.Footer >
          <span className='btn-primary rounded-lg text-white  px-2'  >#{(tagsMap.get(entries[currentTextIndex][0])||"  ").slice(1,-1)}</span>
            <div className="flex flex-row-reverse">
            
              <ButtonGroup>
                <BsChevronLeft
                  size={30}
                  onClick={handlePrevClick}
                  className={
                    currentTextIndex === 0 ? "text-muted" : "text-primary"
                  }
                />
                <BsChevronRight
                  size={30}
                  onClick={handleNextClick}
                  className={
                    currentTextIndex === entries.length - 1
                      ? "text-muted"
                      : "text-primary"
                  }
                />
                
              </ButtonGroup>

              <span>{currentTextIndex+1}/{entries.length}</span>
            </div>
          </Card.Footer>
        </Card.Body>
      </Card>
      
    );
  };
export default function PromptCard({biodata}){
    const [text, setText] = useState(null);
    const {ApiClient} = UserAuth();
    const navigate = useNavigate();
    const fetchData = async () => {
          
        const fetchedText = await fetchTextFromAPI(ApiClient, navigate,biodata);
        setText(fetchedText);
        
    };
    useEffect(() => {
    
      if(!text){
        fetchData();
      }
    }, [text]);
    return (
        <div className='text-black'>
            {
              text?
              <>
                  <ActualCard text={text}/>
                  <div className='text-center'>
                  <button className="btn btn-secondary mx-auto mt-2" onClick={() => {
                  setText(null);
                  }}>Refresh</button>
                </div>
              </>
              :
              <>
                <PlaceholderCard/>
              </>
            }
            {/* component above this */}
            {/* place the placeholder card if showPlaceholder is true */}
            {/* place the actual card if text is available */}
            
        </div>
    );
}

PromptCard.propTypes = {
    biodata: PropTypes.object,
};

ActualCard.propTypes = {
    text: PropTypes.object,
};
