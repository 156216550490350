import React from 'react';
import {  Routes, Route, Navigate } from 'react-router-dom';
import Playground from './components/Playground/Playground';
import Signin from './components/Auth/Signin';
import { AuthContextProvider } from './components/Auth/AuthContext';
import NavBar from './components/NavBar/NavBar';
import Banner from './components/Banner/Banner';
import Protected from './components/Auth/Protected';
import PropTypes from 'prop-types';
function App({config}) {
  
  return (
      <AuthContextProvider baseUrl={config.baseUrl}>
        <div className='bg-site bg-no-repeat bg-cover overflow-auto h-screen'>
          <NavBar />
          <Banner />
          <Routes>
            <Route path="/" element={
              <Protected>
                <Playground config={config} />
              </Protected>
            }
            />
            <Route path="/signin" element={<Signin />}/>
            <Route path='*' element={<Navigate to={{pathname:'/'}}/>}/>
          </Routes>
        </div>
      </AuthContextProvider>
  );
}

App.propTypes = {
  config: PropTypes.object.isRequired
}

export default App;
