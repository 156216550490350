import React from "react";
import PropTypes from "prop-types";
import { useContext,
    createContext, 
    useEffect
    // , useState 
} from "react";
// import { useNavigate } from 'react-router-dom';
import config from "../../config";
// import { GoogleAuthProvider,
//     signInWithPopup,
//     // signInWithRedirect
//     signOut,
//     // onAuthStateChanged
// } from "firebase/auth";
// import { auth } from "../../firebase";
import axios from "axios";
const AuthContext = createContext();






export const AuthContextProvider = ({baseUrl,children}) => {
    // const [user, setUser] = useState(null);
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [token, setToken] = useState(null);
    const ApiClient = axios.create(
        {
            baseURL: config.baseUrl
        }
    );
    ApiClient.interceptors.request.use(
        async (config) => {
            const token = localStorage.getItem('authToken');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            console.log("I'm here 1");
            Promise.reject(error);
        }
    );
    
    ApiClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
    
            console.log("I'm here 2");
            if (error.response && error.response.status === 401) {
                // setToken(null);
                if(localStorage){
                    localStorage.removeItem('authToken');
                }
            }
            return Promise.reject(error);
        }
    );



    // const googleSignIn = () => {
    //     const Provider = new GoogleAuthProvider();
    //     signInWithPopup(auth, Provider)
    //     // signInWithRedirect(auth, Provider)
    // }
    const captchaSignIn = async (token) => {
        try{
            const response = await axios.post(`${baseUrl}/api/login`,{"token":`Bearer ${token}`});
            const authToken = response.data.token;
            if(localStorage){
                localStorage.setItem('authToken', authToken); // Save the token to localStorage
                // localStorage.removeItem('_grecaptcha');
            }
            // setToken(authToken);
            // setIsAuthenticated(true);
            // console.log("response",response);
        }catch(error){
            console.log("error ",error);
        }
    }

    const verifyToken = async () => {
        
        await ApiClient.post(`/api/verify_login`, {});
        if(localStorage)
        {
            const authToken = localStorage.getItem('authToken');
            if(!authToken){
                // setToken(authToken);
                return false;
            }
        }
        return true;
      }
      

    // const logOut = () => {
    //     signOut(auth);
    // }


    useEffect(() => {
        // console.log('AuthContextProvider useEffect called')
        // if(localStorage){
        //     const authToken = localStorage.getItem('authToken');
        //     if(authToken){
        //         setToken(authToken);
        //     }
        // }
        verifyToken();
        
      }, []);
    return (
        <AuthContext.Provider value={{captchaSignIn, 
                                        verifyToken, 
                                        // logOut, 
                                        // isAuthenticated ,
                                        // token,
                                        ApiClient}}>
            {/* <script src="https://www.google.com/recaptcha/api.js" async defer></script> */}
            {children}
        </AuthContext.Provider>
    );
}

AuthContextProvider.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    children: PropTypes.node
}

export const UserAuth = () => {
    return useContext(AuthContext);
}

