import React, { useState} from 'react';
// import { Stack} from 'react-bootstrap'
import './Playground.css'
import MatchBio from '../MatchBio/MatchBio';
import ImageUploader from '../ImageUploader/ImageUploader';
import PromptCard from '../PromptCard/PromptCard';
// import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import Instructions from '../Instructions/Instructions';

export default function Playground({config}){
    
    const [biodata, setBiodata] = useState(null);
    
    // const [getPrompt, setGetPrompt] = useState(false);
    // const [matchBioReady, setMatchBioReady] = useState(false);
    
    const handleImageUploadSuccess = (data) =>{
        console.log("incoming objectified data: ", data)
        setBiodata(data);
    } 
    const handleCloseBio = () => {
        setBiodata(null);
        // setGetPrompt(false);
    };
    // const handleScroll = (event) => {
    //     const bottom =
    //       event.target.scrollHeight - event.target.scrollTop ===
    //       event.target.clientHeight;
    //     if (bottom && biodata && matchBioReady) {
    //         setGetPrompt(true);
    //         // console.log("get prompt");
    //     }
        
    // };
    // const handleTransitionEnd = () => {
    //     // console.log("transition end");
    //     setMatchBioReady(true);
    // };
    return (
        <div className='container max-auto'id='AppBody'>
            {(biodata==null)?
            <div>
                <Instructions/>
                <ImageUploader 
                    baseUrl={config.baseUrl}
                    onUploadSuccess={handleImageUploadSuccess}/>
                    
            </div> :

            <div className='flex flex-col'>
                <div className='px-4 py-2 m-2'>
                    <MatchBio  biodata={biodata} 
                        handleCloseBio={handleCloseBio}
                        // setGetPrompt={setGetPrompt}
                        />

                    
                </div>
                <div className='px-4 py-2 m-2'>
                    <PromptCard baseUrl={config.baseUrl} biodata={biodata} />
                </div>
                    
            </div>
            
            }
            
                
        </div>
    );
}

Playground.propTypes = {
    config: PropTypes.object.isRequired,
}
