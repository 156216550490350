import React from 'react';
// import HeroImage from '../../assets/feature1.png';
// type animation
// import {TypeAnimation} from 'react-type-animation'
// motion
import {motion} from 'framer-motion'
//
import {fadeIn} from '../../variants'

const Banner = () => {
  return (
  <section>
      <div className='container mx-auto mt-5 mb-5'>
          <div className='flex flex-col gap-y-8 lg:flex-row'>
                <div className='flex-1 text-center font-secondary lg:text-left lg:items-center'>
                    <motion.h1
                        variants={fadeIn('up', 0.4)} 
                        initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.7}} 
                        className='text-[35px] font-bold leading-1 lg:text-[70px] lg:text-left'>
                        break the ice, spark a connection!
                    </motion.h1>
                    {/* <motion.div 
                        variants={fadeIn('up', 0.4)}
                        initial='hidden' whileInView={'show'}
                        viewport={{once: false, amount: 0.7}}
                        className='h-[30px] lg:h-[40px] mt-3 text-[28px] lg:text-[40px] font-secondary leading-[1] lg:text-left'>
                        <span className='mr-4'>
                            step 1, step 2, step 3 
                        </span>
                    </motion.div> */}
                </div>
                {/* <motion.div 
                    variants={fadeIn('down', 0.4)}
                    initial='hidden' whileInView={'show'}
                    viewport={{once: false, amount: 0.7}}
                    className='flex justify-center items-center'>
                    <img src={HeroImage} alt='' />
                </motion.div> */}
          </div>
      </div>
  </section>
  );
};

export default Banner;



// import React from 'react';

// import hero data
// import { heroData } from '../data';

// import components
// import Header from './Header';
// import HeroImage from '../../assets/feature1.png';

// const Banner = () => {
//   // destructure hero
// //  const { title, subtitle, btnText, image } = heroData;
//   return (
//     <section>
//       <div className='container mx-auto h-full relative'>
//         <div className='flex flex-col xl:flex-row items-center h-full md:py-24'>
//           {/* text */}
//           <div className='text-center xl:text-left xl:absolute'>
//             {/* title */}
//             <h1
//               className='h1 xl:max-w-[700px] mb-6 xl:mb-12'
//               data-aos='fade-down'
//               data-aos-delay='400'
//             >
//               Slideme.in
//             </h1>
//             {/* subtitle */}
//             <p
//               className='lead xl:max-w-[380px] mb-6 lg:mb-12'
//               data-aos='fade-down'
//               data-aos-delay='500'
//             >
//                 We use advanced algorithms to analyze the images on dating app profiles and come up with creative 
//                 conversation starters just for you. Simply upload the profile, and we'll take care of the rest. With our
//                 help, you'll have a perfect icebreaker that's sure to get the conversation flowing in no time.
//             </p>
//             {/* <button
//               className='btn btn-primary mb-8 xl:mb-0'
//               data-aos='fade-down'
//               data-aos-delay='600'
//             >
//               button
//             </button> */}
//           </div>
//           {/* image */}
//           <div
//             className='xl:absolute xl:-right-12 xl:bottom-16'
//             data-aos='fade-up'
//             data-aos-delay='700'
//           >
//             <img src={HeroImage} alt='' />
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };
// export default Banner;